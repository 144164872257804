
    .quality-course-create {
        height: 100%;
        display: flex;
        flex-direction: column;
        .header-wrapper {
            width: 100%;
            background: #fff;
            border-bottom: 1px solid #dcdfe6;
            .header {
                position: relative;
                width: 1200px;
                height: 60px;
                line-height: 60px;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
            }
        }
        .quality-course-content {
            flex: 1;
            overflow: hidden;
            .content-item {
                * {
                    box-sizing: border-box;
                }
                width: 50%;
                height: 100%;
                box-sizing: border-box;
                float: left;
                .content-left {
                    height: calc(100% - 20px);
                    background-color: #fff;
                    border: 1px solid #dcdfe6;
                    margin: 0 10px 20px 0;
                    border-radius: 4px;
                    overflow: auto;
                    ::v-deep .el-scrollbar__wrap {
                        overflow-x: hidden;
                    }
                    .item-item {
                        padding: 0 15px;
                        .panel-heading {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .course-head-tit {
                                position: relative;
                                font-size: 16px;
                                padding: 10px 0;
                                line-height: 30px;
                                background: #fff;
                                border-bottom: 1px solid #f5f5f5;
                                font-weight: 700;
                            }

                        }
                        .panel-body {
                            position: relative;
                            padding: 15px 0;
                            .task-list-header {
                                height: 30px;
                                padding-top: 7px;
                                padding-bottom: 10px;
                                font-size: 16px;
                                line-height: 30px;
                                background-color: #fff;
                                margin-bottom: 18px;
                                .pull-right {
                                    display: flex;
                                    float: right;
                                    .el-button {
                                        margin-right: 10px;
                                        padding: 7px 20px;
                                    }
                                    .el-dropdown {
                                        color: #FFF;
                                        background-color: #409EFF;
                                        border-color: #409EFF;
                                        border-radius: 4px;
                                        padding: 0 10px;
                                        height: 30px;
                                        line-height: 30px;
                                        cursor: pointer;
                                    }
                                }
                            }
                            .task-manage-list {
                                margin: 0 10px 0 40px;
                                padding: 0;
                                list-style: none;
                                border-left: 1px solid #c1c1c1;
                                border-top: none;
                                border-bottom: none;
                                .task-manage-item {
                                    height: 42px;
                                    line-height: 42px;
                                    border-bottom: none;
                                    border: 1px solid #c1c1c1;
                                    background: #fff;
                                    margin-bottom: 15px;
                                    margin-left: 20px;
                                    cursor: move;
                                    word-wrap: break-word;
                                    word-break: break-all;
                                    .item-content {
                                        width: 100%;
                                        padding-left: 10px;
                                        float: left;
                                        height: 42px;
                                        line-height: 42px;
                                        box-sizing: border-box;
                                    }
                                    .text-overflow {
                                        .mrm {
                                            margin-right: 10px!important;
                                        }
                                        .mls {
                                            margin-left: 5px!important;
                                        }
                                        .color-gray {
                                            color: #919191!important;
                                        }
                                    }
                                    .item-actions {
                                        position: absolute;
                                        right: 11px;
                                        padding-right: 0;
                                         /*visibility: hidden;*/
                                        /*background: #f3f3f3;*/
                                        border-left: 1px solid #ddd;
                                        .el-button--text {
                                            padding-left: 8px;
                                            padding-right: 8px;
                                        }
                                        .el-dropdown {
                                            padding: 0 9px;
                                            cursor: pointer;
                                        }
                                        .mrs {
                                            margin-right: 5px!important;
                                        }
                                    }
                                    &:hover {
                                        background: #f3f3f3;
                                        .item-actions {
                                            visibility: visible;
                                        }
                                    }
                                    .item-line {
                                        border-bottom: 1px solid #c1c1c1;
                                        vertical-align: top;
                                        display: inline-block;
                                        height: 21px;
                                        width: 21px;
                                        margin-left: -21px;
                                        float: left;
                                    }
                                }
                                .task-manage-chapter {
                                    position: relative;
                                    margin-left: -40px;
                                }
                                .task-manage-unit {
                                    margin-left: -20px;
                                }
                                .active {
                                    background: #f3f3f3;
                                }
                            }
                            .danyi-list {
                                .task-manage-chapter {
                                    display: flex;
                                    .item-content {
                                        width: 50%;
                                        border-right: 1px solid #c1c1c1;
                                    }
                                    .js-task-manage-item {
                                        width: 50%;
                                        border: none;
                                        margin: 0;
                                        .text-overflow {
                                            border-right: none;
                                        }
                                    }
                                }
                            }
                            .genrel-item {
                                width: inherit;
                                height: 45px;
                                padding-left: 8px;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                .item-desc {
                                    width: 100px;
                                    font-size: 16px;
                                    color: #666;
                                    text-align: center;
                                }
                                .item-title-act {
                                    height: 100%;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    flex: 1;
                                    padding: 0 10px;
                                    .item-actions {
                                        display: none;
                                    }
                                    
                                }
                                &:hover {
                                    box-shadow: 1px 1px 1px 1px #D9ECFF;
                                    .item-actions {
                                        display: block;
                                    }
                                } 
                                .move-area {
                                    height: 100%;
                                    flex: 1;
                                    &.move-selected {
                                        &::after {
                                            content: '\677e\5f00\9f20\6807\653e\5230\6b64\4f4d\7f6e';
                                            height: 100%;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            background-color: #B3D8FF;
                                            color: #409EFF;
                                        }
                                        .d-tr {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .content-right {
                    height: calc(100% - 20px);
                    background-color: #fff;
                    border: 1px solid #dcdfe6;
                    margin: 0 0 20px 10px;
                    border-radius: 4px;
                    overflow: auto;
                    ::v-deep .el-scrollbar__wrap {
                        overflow-x: hidden;
                    }
                    .index-container {
                        width: 100%;
                        height: calc(100% - 40px);
                        box-sizing: border-box;
                        padding: 10px 15px;
                        box-shadow: none;
                        .course-tabs {
                            padding: 0;
                            height: 100%;
                        }
                        .index-tabs .tab-txt .iconfont {
                            font-size: 18px;
                            margin-right: 5px;
                            position: relative;
                            top: -2px;
                        }
                    }
                }
            }
            .dialog-footer {
                .el-button--primary {
                    background-color: #409EFF !important;
                    border-color: #409EFF !important;
                }
            }
        }
    }
