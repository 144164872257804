
    .select-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .select-left {
            display: flex;
        }
    }

    .type-wrapper {
        margin-top: 10px;
        display: flex;

        .type-name {
            width: 100px;
            margin-top: 10px;
            line-height: 30px;
            color: #000;
        }

        .type-list {
            .type-item {
                display: inline-block;
                padding: 4px 10px;
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
                border-radius: 4px;
                margin: 10px 10px 0 0;
                font-size: 14px;
                border: 1px solid transparent;
                cursor: pointer;
                color: #666;
                transition: all .3s ease-in;

                &.current {
                    color: #409eff;
                    background: #ecf5ff;
                    border: 1px solid #d9ecff;
                }

                &:hover {
                    color: #409eff;
                }
            }
        }
    }

    .table-content {
        .img-thumb {
            width: 60px;
            height: 60px;
            overflow: hidden;

            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .detail-box {
            display: flex;
            justify-content: center;

            img {
                max-width: 50px;
                max-height: 50px;
            }

            .goods-name {
                line-height: 4;
            }
        }
    }

    .video-dialog {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .5);
        z-index: 9;

        .close-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 30px;
            width: 30px;
            height: 30px;
            line-height: 1;
            background: rgba(0, 0, 0, .7);
            color: #fff;
            cursor: pointer;
            transition: all .3s;

            &:hover {
                color: #409EFF;
            }
        }

        .video-content {
            width: 50%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 3px solid #111;

            video {
                outline: none;
                display: block;
            }
        }
    }

    .upload-btn {
        position: relative;

        input {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
