
    .select-wrapper {
        .re-class-list {
            width: 300px;
        }
        .res-search-value {
            width: 240px;
        }
    }
    .select-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .type-wrapper {
        margin-top: 20px;
        display: flex;
        .type-name {
            width: 100px;
            margin-top: 10px;
            line-height: 30px;
            color: #000;
        }
        .type-list {
            .type-item {
                display: inline-block;
                padding: 4px 10px;
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
                border-radius: 4px;
                margin: 10px 10px 0 0;
                font-size: 14px;
                border: 1px solid transparent;
                cursor: pointer;
                color: #666;
                transition: all .3s ease-in;
                &.current {
                    color: #409eff;
                    background: #ecf5ff;
                    border: 1px solid #d9ecff;
                }
                &:hover {
                    color: #409eff;
                }
            }
        }
    }
    .table-wrapper {
        position: relative;
        margin-top: 20px;
        .table-content {
            .img-thumb {
                width: 60px;
                height: 60px;
                overflow: hidden;
                img {
                    display: block;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
        .d-table {
            width: 100%;
            max-height: 550px;
            border: 1px solid #EBEEF5;
            border-right: none;
            position: relative;
            overflow: hidden;
            box-sizing: border-box;
            flex: 1;
            max-width: 100%;
            font-size: 14px;
            color: #606266;
            background-color: #FFF;
            .d-tbody {
                height: 497px;
            }
            .d-tr {
                height: 53px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                cursor: move;
                .d-th, .d-th-80, .d-th-100 {
                    background-color: rgb(245, 247, 250);
                }
                .d-th-80, .d-th-100, .d-td-80, .d-td-100, .d-th, .d-td {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-right: 1px solid #EBEEF5;
                    border-bottom: 1px solid #EBEEF5;
                }
                .d-th, .d-td {
                    flex: 1;
                    justify-content: flex-start;
                    padding: 0 10px;
                    align-items: center;
                }
                .d-th-80, .d-td-80 {
                    width: 80px;
                }
                .d-th-100, .d-td-100 {
                    width: 100px;
                }
                .d-td-right {
                    border-right: none;
                }
            }
        }
        .one-import {
            position: absolute;
            bottom: 5px;
            left: 10px;
        }
    }
    /*.upload-resources-dialog {*/
        /*.el-dialog {*/
            /*.el-dialog__footer {*/
                /*text-align: center;*/
                /*.el-button+.el-button {*/
                    /*margin-left: 30px;*/
                /*}*/
            /*}*/
        /*}*/
    /*}*/
    .video-dialog {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,.5);
        z-index: 9;
        .close-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 30px;
            width: 30px;
            height: 30px;
            line-height: 1;
            background: rgba(0,0,0,.7);
            color: #fff;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                color: #409EFF;
            }
        }
        .video-content {
            width: 50%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 3px solid #111;
            video {
                outline: none;
                display: block;
            }
        }
    }
    .upload-btn {
        position: relative;
        input {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
    .table-wrapper table {
        width: 100%;
        max-height: 500px;
        border: 1px solid #EBEEF5;
        border-right: none;
        border-bottom: none;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        flex: 1;
        max-width: 100%;
        font-size: 14px;
        color: #606266;
        background-color: #FFF;
        border-spacing: 0;
        thead {
            color: #909399;
            font-weight: 500;
            tr {
                background-color: #FFF;
                height: 44px;
                th {
                    border-bottom: 1px solid #EBEEF5;
                    font-weight: normal;
                    color: rgb(51, 51, 51);
                    background: rgb(245, 247, 250);
                    border-right: 1px solid #EBEEF5;
                }
            }
        }
        tbody {
            tr {
                height: 53px;
                td {
                    border-bottom: 1px solid #EBEEF5;
                    border-right: 1px solid #EBEEF5;
                    text-align: center;
                }
            }
        }
    }
