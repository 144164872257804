
    .exam-paper-content {
        * {
            box-sizing: border-box;
        }
        height: 100%;
        width: 100%;
        .ep-acts {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 10px;
            .class-tit, .classes-list {
                margin-right: 5px;
            }
            .ep-act-search {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex: 1;
                .search-btns {
                    width: 300px;
                }
            }
        }
    }
    .act-btns {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .ep-list {
        height: calc(100% - 80px);
        .table-data {
            position: relative;
            height: calc(100% - 42px);
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .exampaper-import {
                position: absolute;
                left: 10px;
                bottom: -42px;
                z-index: 10;
            }

        }
        .page-block {
            margin-top: 10px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        .d-table {
            border-right: 1px solid #EBEEF5;

        }
    }


