
    .batch-main {
        ul, li {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        .item-main {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 42px;
            margin-bottom: 15px;
            border: 1px solid #c1c1c1;
            .item-text {
                display: flex;
                flex: 1;
                .item-title-editor {
                    min-width: 60px;
                    max-width: 100px;
                }
                padding-left: 10px;
                i {
                    font-size: 16px;
                    margin-right: 5px;
                }
                .editor-status {
                    display: flex;
                    flex: 1;
                    justify-content: flex-start;
                    align-items: center;
                }
            }
            &.active {
                box-shadow: 5px 5px 8px #dddddd;
            }
        }
        .batch-chapters {
            margin-left: 15px;
            .batch-sections {
                margin-left: 15px;
                .batch-resources {
                    margin-left: 15px;
                }
            }
        }
        .item-actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 100%;
            .icon-btn-expand, .icon-btn {
                cursor: pointer;
                font-size: 14px;
                padding: 0 12px;
                i {
                    margin-right: 5px;
                }

            }
        }
    }


